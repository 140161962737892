import React, { useState, useEffect, useContext } from "react";
import handleViewport from "react-in-viewport";
import styles from "./Objectives.module.scss";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import useInViewport from "../../hooks/useInViewport";
import gql from "graphql-tag";
import { isAuthed } from "../../utils/authorization";
import { getYear } from "../../utils/dates";
import { mergeCustomizer } from "../../utils/misc";

import { TableRow, TableCell, MenuItem, Typography, Tooltip, IconButton } from "@material-ui/core";

import { FetchContext } from "../../context/fetchContext";
import { UserContext } from "../../context/userContext";
// import { LoadingContext } from "../../context/loadingContext";

import UserAvatars from "../UserAvatars/UserAvatars";
import Menu from "../Menu/Menu";
import ProgressBarRocks from "../ProgressBar/ProgressBarRocks";
import Status from "../Status/Status";
import StatusIcons from "../StatusIcons/StatusIcons";
import NotesButton from "../Notes/NotesButton";
import Skeleton from "./Skeleton";
import PlanPill from "../PlanPill/PlanPill";
import Icon from "@mdi/react";
import { mdiChevronLeftCircleOutline, mdiChevronRightCircleOutline, mdiDrag } from "@mdi/js";

const Objective = ({
  inViewport,
  forwardedRef,
  category,
  canEdit,
  handleAddIssueDialog,
  objective,
  fiscalYear,
  corpPlans,
  threeYearTieIn,
  plansOrder,
  rockProgressQuarter,
  showAllQuarters,
  handleToggleQuarter,
  showDescription,
  corpForSelectedYear,
  handleEditDialog,
  handleUpdateStatus,
  handleConfirmOpen,
  provided,
}) => {
  const { id, value, description, number, users, status, objectives, plan, metrics } = objective;
  const params = useParams();
  const { fetch } = useContext(FetchContext);
  const { user } = useContext(UserContext);
  // const { updateLoading } = useContext(LoadingContext);

  const [tieInObjectives, setTieInObjectives] = useState([]);
  const [rockProgressData, setRockProgressData] = useState([{ rocks: [] }]);
  const [currentYear, setCurrentYear] = useState();

  const {
    data,
    refetch,
    loading: threeYearObjLoading,
  } = useQuery(GET_OBJECTIVES_METRICS, {
    variables: { organization: params.org, objectiveIds: objectives, metricIds: metrics },
  });
  const hasRendered = useInViewport(inViewport);

  useEffect(() => {
    if (data && corpPlans) {
      const oneYearObjs = _.get(data, "objectives", []);

      const corpObjs = oneYearObjs.filter((obj) => _.get(obj, "plan.departmentName") === "Corporate");
      const nonCorpObjs = _.difference(oneYearObjs, corpObjs);

      const objsByCorp1 = _.groupBy(corpObjs, "plan.id");
      const objsByCorp2 = _.groupBy(nonCorpObjs, "plan.plan.id");

      const objsByCorp = _.mergeWith(objsByCorp1, objsByCorp2, mergeCustomizer);
      let rocksByCorp = {};
      for (const corpId in objsByCorp) {
        rocksByCorp[corpId] = _.flatten(objsByCorp[corpId].map((obj) => _.get(obj, "rocks", [])));
      }

      let objsData = [],
        rocksData = [];
      _.get(corpPlans, "plans", []).forEach(({ id, year }) => {
        const isCurrentYearPlan = year === fiscalYear;
        const planProperties = { isCurrent: isCurrentYearPlan, year: getYear(year) };
        rocksData.push({ ...planProperties, rocks: _.get(rocksByCorp, id, []) });
        objsData.push({
          ...planProperties,
          objectives: _.sortBy(_.get(objsByCorp, id, []), [
            function (obj) {
              // this preliminary sorting step is required if the list of objectives includes multiple plans
              return plansOrder.indexOf(_.get(obj, "plan.sharedPlanId"));
            },
            "number",
          ]),
        });

        if (isCurrentYearPlan) {
          setCurrentYear(year);
        }
      });

      setRockProgressData(_.sortBy(rocksData, ["year"]));
      setTieInObjectives(_.sortBy(objsData, ["year"]));
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [fetch]);

  // useEffect(() => {
  //   if (!_.isNil(threeYearObjLoading))
  //     updateLoading({
  //       loadingObj: { threeYearObjLoading },
  //       groupKeyStr: "objectives",
  //     });
  // }, [threeYearObjLoading]);

  if (!hasRendered)
    return (
      <TableRow ref={forwardedRef} className={styles.skeletonRow}>
        <Skeleton long />
      </TableRow>
    );

  // const statusObjs =
  //   threeYearTieIn === "current"
  //     ? _.get(_.find(tieInObjectives, { closed: false }), "objectives", [])
  //     : _.flatten(tieInObjectives.map((obj) => _.get(obj, "objectives", [])));

  const statusObjs = _.flatten(tieInObjectives.map((obj) => _.get(obj, "objectives", [])));

  return (
    <>
      <TableRow ref={forwardedRef} className={showDescription ? styles.noBot : undefined}>
        <TableCell align="center">
          {provided ? (
            <div {...provided.dragHandleProps} style={{ opacity: canEdit ? 1 : 0.25 }}>
              <Icon path={mdiDrag} size={1} />
            </div>
          ) : (
            <div>
              <Icon path={mdiDrag} size={1} />
            </div>
          )}
        </TableCell>
        <TableCell align="center">
          <UserAvatars users={users} />
        </TableCell>
        <TableCell>
          <div className={styles.flex}>
            <div>
              {!_.isNil(plan?.departmentName) && <PlanPill plan={plan} />}
              {number}. {value}
            </div>
            <NotesButton
              id={id}
              model="objective"
              value={value.trim()}
              user={users[0] ? users[0].id : null}
              doc={objective}
              tabs={["notes", "issues", "todos"]}
              canEditTodo={canEdit}
              planId={_.get(corpForSelectedYear, "id")}
            />
            {canEdit && (
              <Menu>
                {/* <MenuItem onClick={handleAddIssueDialog(id, "Objective", value.trim(), users[0] ? users[0].id : null)}>Add Issue</MenuItem> */}
                <MenuItem onClick={handleEditDialog(true, objective)}>Edit</MenuItem>
                <MenuItem onClick={handleConfirmOpen(true, objective)} className={styles.delete}>
                  Delete
                </MenuItem>
              </Menu>
            )}
          </div>
        </TableCell>
        <TableCell align="center">
          <StatusIcons items={statusObjs} currentYear={currentYear} />
        </TableCell>
        <TableCell align="center">
          <StatusIcons
            items={_.sortBy(_.get(data, "metrics", []), [
              function (mtrc) {
                // this preliminary sorting step is required if the list of metrics includes multiple plans
                return plansOrder.indexOf(_.get(mtrc, "plan.sharedPlanId"));
              },
              "number",
            ])}
          />
        </TableCell>
        <TableCell align="center">
          <ProgressBar
            rocksData={rockProgressData}
            threeYearTieIn={threeYearTieIn}
            quarterNumber={rockProgressQuarter}
            showAllQuarters={showAllQuarters}
            handleToggleQuarter={handleToggleQuarter}
          />
        </TableCell>
        <TableCell align="center">
          <div className={styles.flexCenter}>
            <Status status={status} />
            {isAuthed(user.user, "department facilitator") && (
              <Menu icon="arrow">
                <MenuItem onClick={handleUpdateStatus("on track", objective)} disabled={status === "on track"}>
                  Mark as on track
                </MenuItem>
                <MenuItem onClick={handleUpdateStatus("off track", objective)} disabled={status === "off track"}>
                  Mark as off track
                </MenuItem>
              </Menu>
            )}
          </div>
        </TableCell>
      </TableRow>

      {showDescription && (
        <TableRow>
          <TableCell colSpan="100%">
            <Typography variant="body2" className={styles.departmentName}>
              {description ? <span className={styles.description}>{description}</span> : "No description"}
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default handleViewport(Objective);

const ProgressBar = ({ rocksData, threeYearTieIn, quarterNumber, showAllQuarters, handleToggleQuarter }) => {
  const getCurrentYearProgress = (rocks, key) => {
    let totalRocksDone = 0;
    rocks.forEach((r) => {
      if (r.status === "complete") totalRocksDone++;
    });
    const rocksByQuarter = _.groupBy(rocks, "index");

    return (
      <React.Fragment key={key}>
        {showAllQuarters ? (
          <>
            <ProgressBarRocks rocks={rocksByQuarter[1]} tooltipTitle="Q1" />
            <ProgressBarRocks rocks={rocksByQuarter[2]} tooltipTitle="Q2" />
            <ProgressBarRocks rocks={rocksByQuarter[3]} tooltipTitle="Q3" />
            <ProgressBarRocks rocks={rocksByQuarter[4]} tooltipTitle="Q4" />
          </>
        ) : (
          <>
            <IconButton onClick={() => handleToggleQuarter(-1)} disabled={quarterNumber === 0}>
              <Icon path={mdiChevronLeftCircleOutline} size={1} />
            </IconButton>
            <ProgressBarRocks rocks={rocksByQuarter[quarterNumber + 1]} tooltipTitle={`Q${quarterNumber + 1}`} />
            <IconButton onClick={() => handleToggleQuarter(1)} disabled={quarterNumber === 3}>
              <Icon path={mdiChevronRightCircleOutline} size={1} />
            </IconButton>
          </>
        )}
        <Typography variant="body2" className={styles.rocksCompleteText}>
          {totalRocksDone}/{rocks.length}
        </Typography>
      </React.Fragment>
    );
  };

  return (
    <div className={styles.flexCenter}>
      {rocksData.map(({ isCurrent, year, rocks }, idx) => {
        return !isCurrent
          ? threeYearTieIn === "all" && <ProgressBarRocks alt={true} key={idx} rocks={rocks} tooltipTitle={`${year - 1} - ${year}`} />
          : getCurrentYearProgress(rocks, idx);
      })}
    </div>
  );
};

const GET_OBJECTIVES_METRICS = gql`
  query Objective_GetObjsMetrics($organization: ID!, $objectiveIds: [ID!], $metricIds: [ID!]) {
    objectives(organization: $organization, ids: $objectiveIds) {
      id
      value
      status
      number
      rocks {
        index
        status
        successCriterias {
          done
        }
      }
      plan {
        id
        year
        color
        shortName
        departmentName
        sharedPlanId
        plan {
          id
          year
        }
      }
    }

    metrics(organization: $organization, ids: $metricIds) {
      id
      value
      number
      status
      plan {
        id
        color
        shortName
        departmentName
        sharedPlanId
      }
    }
  }
`;
