import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@material-ui/core";
import SelectUsers from "../SelectUsers/SelectUsers";
import SelectObjectives from "../SelectObjectives/SelectObjectives";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import { UserContext } from "../../context/userContext";
import Loading from "../Loading/Loading";
import { OBJECTIVE_FIELDS } from "../../utils/fragments";

const initErrorForm = {
  value: ["required"],
};

const EditDialog = ({ open, handleClose, objective, category, snack, corpForSelectedYear, planId }) => {
  const { id, value, description, plan, users, objectives } = objective;
  const params = useParams();
  const { user } = useContext(UserContext);
  const { sharedPlanId } = _.get(user, "departmentFilter");
  const oneYearCorpPlan = _.get(corpForSelectedYear, "id");

  const initForm = { value, description, users: (users || []).map((u) => u.id), objectives, plan: _.get(plan, "id") };
  const { fetch, requestFetch } = useContext(FetchContext);
  const [updateObjective] = useMutation(UPDATE_OBJECTIVE);
  const {
    data,
    refetch,
    loading: queryLoading,
  } = useQuery(GET_USERS_AND_OBJECTIVES_AND_PLANS, {
    variables: {
      organization: params.org,
      category,
      sharedPlanId,
      oneYearCorpPlan: planId,
      closed: _.isNil(oneYearCorpPlan) ? false : null,
    },
  });

  const { form, formErrors, handleChange, validateForm, resetForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const { value, description, users, objectives, plan } = form;
    let desc = description;
    const ok = await updateObjective({
      variables: {
        id,
        value,
        description: desc,
        users,
        objectives,
        plan,
      },
    });

    if (ok) {
      snack(`Updated "${value}" objective`);
      handleClose();
    }
  };

  useEffect(() => {
    resetForm(initForm);
  }, [id]);

  useEffect(() => {
    refetch();
  }, [fetch]);

  if (queryLoading)
    return (
      <Dialog
        open={open}
        fullWidth
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <Loading />
      </Dialog>
    );

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <DialogTitle>Edit Objective</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Objective Title"
          name="value"
          fullWidth
          variant="outlined"
          value={form.value || ""}
          onChange={handleChange}
          multiline
          helperText={formErrors.value}
          error={Boolean(formErrors.value)}
        />
        <TextField
          label="Why and How"
          name="description"
          fullWidth
          multiline
          variant="outlined"
          value={form.description || ""}
          onChange={handleChange}
          margin="normal"
        />
        <SelectObjectives
          name="objectives"
          objectives={_.get(data, "objectives", null)}
          handleChange={handleChange}
          values={form.objectives}
          category={category === "3 year" ? "3 year" : "1 year"}
          multiple
          helperText="Which objectives are tied to this one?"
          plansOrder={_.get(data, "organization.plansOrder")}
        />
        <SelectDepartment plans={_.get(data, "plans")} name="plan" handleChange={handleChange} value={form.plan} />
        <SelectUsers
          name="users"
          users={_.get(data, "users")}
          handleChange={handleChange}
          values={form.users}
          plan={form.plan}
          allPlans={_.get(data, "plans")}
          helperText="Who is accountable for this objective?"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;

const UPDATE_OBJECTIVE = gql`
  ${OBJECTIVE_FIELDS}
  mutation ObjsEditDialog_UpdateObj($id: ID!, $value: String, $description: String, $users: [ID!], $objectives: [ID!], $plan: ID) {
    updateObjective(id: $id, value: $value, description: $description, users: $users, objectives: $objectives, plan: $plan) {
      objective {
        ...ObjectiveFields
      }
      objectives {
        id
        number
        objectives
      }
      plans {
        id
        objectives
      }
    }
  }
`;

const GET_USERS_AND_OBJECTIVES_AND_PLANS = gql`
  query ObjsEditDialog_GetUsersObjsPlans($organization: ID!, $category: String, $sharedPlanId: ID, $oneYearCorpPlan: ID, $closed: Boolean) {
    organization(id: $organization) {
      id
      plansOrder
    }

    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    objectives(organization: $organization, archived: false, sharedPlanId: $sharedPlanId) {
      id
      value
      category
      number
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: $category, oneYearCorpPlan: $oneYearCorpPlan, closed: $closed) {
      id
      departmentName
      sharedPlanId
    }
  }
`;
