import React, { useEffect, useState, useContext, useMemo } from "react";
import styles from "./ThreeYear.module.scss";
import _, { update } from "lodash";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { fullDate, fiscalYearStart, subToDate, year, addToDate } from "../../utils/dates";
// import { getOneYearObjectives } from "../../utils/graphql";
import { isAuthed } from "../../utils/authorization";
import useMobileMenu from "../../hooks/useMobileMenu";

import { FetchContext } from "../../context/fetchContext";
// import { LoadingContext } from "../../context/loadingContext";

import { Container, Grid, Button, Typography, IconButton, MenuItem, Tooltip } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import EditDialog from "./EditDialog";
import Loading from "../../components/Loading/Loading";
import Objectives from "../../components/Objectives/Objectives";
import Metrics from "../../components/Metrics/Metrics";
import NotesButton from "../../components/Notes/NotesButton";
import { GET_METRICS, GET_OBJECTIVES } from "../../utils/query";
import Menu from "../../components/Menu/Menu";
import { Lock, LockOpen } from "@material-ui/icons";
import useThreeYearCorpPlan from "../../hooks/useThreeYearCorpPlan";
import { SnackbarContext } from "../../context/snackbarContext";
import CreateThreeYearDialog from "./CreateThreeYearDialog";

const ThreeYear = ({ user, params, org, tab }) => {
  const isAdmin = isAuthed(user.user, "department facilitator");
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  // const { updateLoading } = useContext(LoadingContext);
  const fiscalYear = org.fiscalYear;

  const [editDialog, setEditDialog] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);
  // const [objectiveIds, setObjectiveIds] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const {
    corpForSelectedThreeYear,
    setCorpForSelectedThreeYear,
    threeYearCorpPlans,
    currentCorpPlan,
    loading: corpPlansLoading,
  } = useThreeYearCorpPlan({
    orgId: params.org,
  });
  const { isMobile } = useMobileMenu();

  const {
    loading: threeYear_plansLoading,
    data,
    refetch: refetchPlans,
  } = useQuery(GET_3_YEAR_PLANS, {
    variables: { organization: params.org, sharedPlanId: user.departmentFilter.sharedPlanId },
  });

  const [getThreeYearObjectives, { data: objectivesData, loading: objectivesLoading, refetch: refetchObjectives }] =
    useLazyQuery(GET_OBJECTIVES);
  const [getThreeYearMetrics, { data: metricsData, loading: metricsLoading, refetch: refetchMetrics }] = useLazyQuery(GET_METRICS);

  const [togglePlanLock] = useMutation(TOGGLE_3_YEAR_PLAN_LOCK);

  const handleTogglePlanLock = async () => {
    const locked = _.get(corpForSelectedThreeYear, "locked");
    const ok = await togglePlanLock({
      variables: { corporatePlanId: _.get(corpForSelectedThreeYear, "id") },
    });

    if (ok.data.togglePlanLock) {
      snack(`Three Year Plan has been ${locked ? "un" : ""}locked`);
    }
  };

  const handleCreateYearDialog = (open) => () => {
    setCreateDialog(open);
  };

  const handleEditDialog = (open) => () => {
    setEditDialog(open);
  };

  const getThreeYearRange = (targetDate) => {
    return `${fullDate(subToDate(targetDate, { years: 3, days: -1 }), 0)} - ${fullDate(targetDate)}`;
  };

  const initForm = useMemo(
    () => ({
      useDepartmentTheme: _.get(selectedPlan, "useDepartmentTheme"),
      theme: _.get(selectedPlan, "theme"),
      color: _.get(selectedPlan, "color"),
      shortName: _.get(selectedPlan, "shortName"),
    }),
    [selectedPlan]
  );

  useEffect(() => {
    if (!_.isEmpty(selectedPlan)) {
      refetchPlans();
      if (refetchObjectives) {
        refetchObjectives();
      }
      if (refetchMetrics) {
        refetchMetrics();
      }
    }
  }, [fetch]);

  // useEffect(() => {
  //   updateLoading({ loadingObj: { threeYear_plansLoading } });
  // }, [threeYear_plansLoading]);

  // useEffect(() => {
  //   // Get 1 year objectives to calculate total 3 year progress
  //   const getObjectiveIds = async () => {
  //     const res = await getOneYearObjectives({
  //       organization: params.org,
  //       ids: _.flatten(_.get(data, "plan.objectives", []).map((o) => o.objectives)),
  //     });
  //     return _.map(res.data.objectives, "id");
  //   };

  //   if (data) {
  //     setObjectiveIds(getObjectiveIds());
  //   }
  // }, [data]);

  useEffect(() => {
    if (data) {
      let selPlan;
      if (_.isNil(user.departmentFilter.id)) {
        const corpPlan = _.find(data.corporatePlans, { category: "3 year", targetDate: corpForSelectedThreeYear.targetDate });
        selPlan = _.pick(corpPlan, ["id", "theme", "targetDate", "notes", "color", "shortName", "departmentName", "useDepartmentTheme"]);
      } else {
        const planWithSameTargetDate = _.find(data.plans, { category: "3 year", targetDate: corpForSelectedThreeYear.targetDate });
        selPlan = _.pick(planWithSameTargetDate, [
          "id",
          "theme",
          "targetDate",
          "useDepartmentTheme",
          "notes",
          "color",
          "shortName",
          "departmentName",
        ]);
      }
      setSelectedPlan(selPlan);

      setLoadedFirstTime(true);
    }
  }, [data, corpForSelectedThreeYear]);

  useEffect(() => {
    if (!_.isEmpty(corpForSelectedThreeYear)) {
      getThreeYearObjectives({
        variables: {
          organization: params.org,
          planId: corpForSelectedThreeYear.id,
          sharedPlanId: user.departmentFilter.sharedPlanId,
        },
      });
      getThreeYearMetrics({
        variables: {
          organization: params.org,
          planId: corpForSelectedThreeYear.id,
          sharedPlanId: user.departmentFilter.sharedPlanId,
        },
      });
    }
  }, [corpForSelectedThreeYear, user.departmentFilter.sharedPlanId]);

  if (threeYear_plansLoading || objectivesLoading || metricsLoading || corpPlansLoading) return <Loading />;
  const plansOrder = _.get(data, "organization.plansOrder", []);
  const nextPlanYearEnd = `${year(addToDate(currentCorpPlan.targetDate, { years: 3 }))}`;
  const nextPlanYearStart = `${year(currentCorpPlan.targetDate)}`;
  const nextPlanYearRange = `${nextPlanYearStart} - ${nextPlanYearEnd}`;

  const corpPlans = _.get(data, "corporatePlans", []);
  const currentOneYearCorpPlan = _.find(corpPlans, { category: "1 year", year: fiscalYear });
  const openYear = _.get(currentOneYearCorpPlan, "year");
  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {/* <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h6" className={styles.label}>
                3-Year Plan
              </Typography>
            </Grid>
          </Hidden> */}
          <Grid item xs={12}>
            <div className={styles.flexBetween}>
              <div className={styles.container}>
                <div>
                  <div className={styles.flex}>
                    <Typography variant="h5">
                      <span className={styles.label}>Theme: </span>
                      {selectedPlan.useDepartmentTheme ? selectedPlan.theme : corpForSelectedThreeYear.theme}
                    </Typography>
                    <NotesButton id={selectedPlan.id} model="plan" doc={selectedPlan} />
                    {isAdmin && (
                      <IconButton onClick={handleEditDialog(true)} size="small">
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </div>
                  <div className={styles.flex}>
                    <Typography variant="subtitle1">
                      <div className={`${styles.label} ${styles.flex}`}>
                        {corpForSelectedThreeYear.targetDate && (
                          <>
                            <span>Year: {getThreeYearRange(corpForSelectedThreeYear.targetDate)}</span>
                            {isAdmin && (
                              <>
                                {corpForSelectedThreeYear.locked ? (
                                  <Tooltip title="Unlock 3 year plan">
                                    <IconButton onClick={handleTogglePlanLock} size="small">
                                      <Lock fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Lock 3 year plan">
                                    <IconButton onClick={handleTogglePlanLock} size="small">
                                      <LockOpen fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Typography>

                    <Menu icon="arrow">
                      {_.sortBy(threeYearCorpPlans, (p) => parseInt(-p.targetDate)).map((plan) => (
                        <MenuItem
                          key={plan.id}
                          onClick={() => setCorpForSelectedThreeYear(plan)}
                          disabled={plan.id === corpForSelectedThreeYear.id}
                          selected={plan.id === corpForSelectedThreeYear.id}
                        >
                          {getThreeYearRange(plan.targetDate)} {plan.locked && <Lock fontSize="inherit" />}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              </div>
              <div className={styles.flexCenter}>
                {isAdmin && (
                  <>
                    <Tooltip title={"Create new 3 year plan"}>
                      <div>
                        <Button color="primary" variant="contained" onClick={handleCreateYearDialog(true)}>
                          Create New 3 Year Plan
                        </Button>
                      </div>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </Grid>
          {tab === "objectives" && (
            <Grid item xs={12}>
              <Objectives
                objectives={_.get(objectivesData, "objectives", [])}
                fiscalYear={fiscalYear}
                plansOrder={plansOrder}
                category="3 year"
                corpForSelectedYear={currentOneYearCorpPlan}
                planId={corpForSelectedThreeYear.id}
                locked={_.get(corpForSelectedThreeYear, "locked", false)}
                variables={{
                  organization: params.org,
                  planId: corpForSelectedThreeYear.id,
                  sharedPlanId: user.departmentFilter.sharedPlanId,
                }}
              />
            </Grid>
          )}
          {tab === "metrics" && (
            <Grid item xs={12}>
              <Metrics
                metrics={_.get(metricsData, "metrics", [])}
                fiscalYear={fiscalYear}
                plansOrder={plansOrder}
                category="3 year"
                planId={corpForSelectedThreeYear.id}
                yearOne={openYear}
                corpForSelectedYear={currentOneYearCorpPlan}
                locked={_.get(corpForSelectedThreeYear, "locked", false)}
                variables={{
                  organization: params.org,
                  planId: corpForSelectedThreeYear.id,
                  sharedPlanId: user.departmentFilter.sharedPlanId,
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>

      <EditDialog
        open={editDialog}
        handleClose={handleEditDialog(false)}
        planId={selectedPlan.id}
        initForm={initForm}
        isCorporate={selectedPlan.departmentName === "Corporate"}
      />

      {corpForSelectedThreeYear.targetDate && (
        <>
          {createDialog && (
            <CreateThreeYearDialog
              open={createDialog}
              handleClose={handleCreateYearDialog(false)}
              nextPlanYearEnd={nextPlanYearEnd}
              nextPlanYearRange={nextPlanYearRange}
              organizationId={params.org}
            />
          )}
        </>
      )}
    </>
  );
};

export default ThreeYear;

const GET_3_YEAR_PLANS = gql`
  query ThreeYear_GetThreeYear($organization: ID!, $sharedPlanId: ID) {
    plans(organization: $organization, category: "3 year", sharedPlanId: $sharedPlanId) {
      id
      theme
      useDepartmentTheme
      targetDate
      departmentName
      sharedPlanId
      color
      shortName
      notes {
        id
        date
      }
    }

    corporatePlans: plans(organization: $organization, departmentName: "Corporate", closed: false) {
      id
      theme
      targetDate
      color
      shortName
      departmentName
      useDepartmentTheme
      notes {
        id
        date
      }
      category
      year
    }

    organization(id: $organization) {
      id
      fiscalYear
      plansOrder
    }
  }
`;

const TOGGLE_3_YEAR_PLAN_LOCK = gql`
  mutation ThreeYear_TogglePlanLock($corporatePlanId: ID!) {
    togglePlanLock(corporatePlanId: $corporatePlanId) {
      id
      locked
    }
  }
`;
