import React, { useContext, useState } from "react";
import _ from "lodash";
import styles from "./Navbar.module.scss";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { isSameDay } from "date-fns";
import { UserContext } from "../../context/userContext";

import {
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  Link as MUILink,
  useTheme,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import {
  mdiHelp,
  mdiBackburger,
  mdiForwardburger,
  mdiInformation,
  mdiCheckboxMarked,
  mdiAlertDecagram,
  mdiMenuDown,
  mdiArrowLeft,
} from "@mdi/js";

import Icon from "@mdi/react";
import Announcement from "../Announcement/Announcement";
import ReportDialog from "../ReportDialog/ReportDialog";
import { Link, useLocation, useHistory } from "react-router-dom";
import ContactDialog from "../ContactDialog/ContactDialog";
import PlanPill from "../PlanPill/PlanPill";
import { isAuthed } from "../../utils/authorization";

// import logo from "../../assets/img/icon-white.png";

const Navbar = ({ params, handleOpen, snack, open, dialog, setDialog, org, orgs }) => {
  const { user } = useContext(UserContext);
  const fiscalYear = org.fiscalYear;
  const [announcementOpen, setAnnoucmentOpen] = useState(
    localStorage.getItem("announcementClosed") ? !isSameDay(new Date(), new Date(localStorage.getItem("announcementClosed"))) : true
  );
  const { pathname } = useLocation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [orgsAnchor, setOrgsAnchor] = useState(null);

  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data, loading } = useQuery(GET_ORG_AND_DEPARTMENTS, {
    variables: { id: params.org, user: user.user.id },
  });

  const handleAnnoucment = (open) => () => {
    setAnnoucmentOpen(open);
    if (!open) {
      localStorage.setItem("announcementClosed", new Date());
    }
  };

  const renderSelectedDept = () => {
    const selectedPlan = _.get(data, "plans", []).find((plan) => plan.sharedPlanId === user.departmentFilter.sharedPlanId);
    // return _.get(selectedPlan, "departmentName", "All Departments");
    return <PlanPill plan={selectedPlan} noPlanVal={"ALL"} />;
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  // const handleOpenOrgsMenu = (e) => {
  //   setOrgsAnchor(e.currentTarget);
  // };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseOrgsMenu = (e) => {
    setOrgsAnchor(null);
  };

  const handleAddDialog =
    (category, referenceId = null, referenceModel = null) =>
    () => {
      const currentCorpPlan = _.get(data, "plans", []).find((plan) => plan.departmentName === "Corporate" && plan.year === fiscalYear);
      setDialog({ ...dialog, addTodoDialog: { open: true, category, referenceId, referenceModel, planId: _.get(currentCorpPlan, "id") } });
    };

  const handleClickOrg = (orgId) => {
    history.push(`/${orgId}`);
    handleCloseOrgsMenu();
  };

  return (
    <>
      <Drawer variant="permanent" anchor="top" PaperProps={{ className: styles.paper }}>
        <IconButton onClick={handleOpen}>
          <Icon path={open ? mdiBackburger : mdiForwardburger} size={1} color="#fff" />
        </IconButton>

        <Typography className={styles.name}>
          {!loading && `${_.get(data, "organization.name", "")}${_.get(data, "organization.active") ? "" : " (DISABLED)"}`}
        </Typography>
        {isAuthed(user.user, "ventrek facilitator") && orgs && (
          <>
            {/* <IconButton onClick={handleOpenOrgsMenu} className={styles.selectOrg}>
              <Icon path={mdiMenuDown} size={1} color="#fff" />
            </IconButton>
            <Menu id="orgs-menu" anchorEl={orgsAnchor} open={Boolean(orgsAnchor)} onClose={handleCloseOrgsMenu}>
              <MenuItem>
                <Link to="/">
                  <Typography>Go to Selection Page</Typography>
                </Link>
              </MenuItem>
              {_.sortBy(orgs, (org) => (org.active ? -1 : 1)).map((organization) => (
                <MenuItem key={organization.id} onClick={() => handleClickOrg(organization.id)}>
                  <Typography>
                    {!loading && `${_.get(organization, "name", "")}${_.get(organization, "active") ? "" : " (DISABLED)"}`}
                  </Typography>
                </MenuItem>
              ))}
            </Menu> */}
            <Link to="/">
              <IconButton className={styles.selectOrg} title="Change organization">
                <Icon path={mdiArrowLeft} size={1} color="#fff" />
              </IconButton>
            </Link>
          </>
        )}
        {!isMobile && renderSelectedDept()}
        {!isMobile && <Typography className={styles.pathname}>{renderPathname(pathname)}</Typography>}
        <div className={styles.right}>
          {!isMobile && (
            <>
              <IconButton onClick={handleAddDialog("todo")}>
                <Icon path={mdiCheckboxMarked} size={1.25} color="#fff" />
              </IconButton>
              <IconButton onClick={handleAddDialog("issue")}>
                <Icon path={mdiAlertDecagram} size={1.25} color="#fff" />
              </IconButton>

              <Divider orientation="vertical" variant="middle" flexItem className={styles.divider} />
            </>
          )}
          <IconButton onClick={handleAnnoucment(true)}>
            <Icon path={mdiInformation} size={1.25} color="#fff" />
          </IconButton>
          <IconButton onClick={handleOpenMenu}>
            <Icon path={mdiHelp} size={1} color="#fff" />
          </IconButton>
          <Menu id="app-bar-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            <MenuList>
              <MenuItem onClick={() => setReportDialogOpen(true)}>
                <Typography>Report a Bug</Typography>
              </MenuItem>
              <MenuItem onClick={() => setContactDialogOpen(true)}>
                <Typography>Contact Us</Typography>
              </MenuItem>
              <MenuItem>
                <Link to="/privacy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                  <Typography>Privacy</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/terms" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                  <Typography>Terms</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/security" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                  <Typography>Security</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/pricing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                  <Typography>Pricing</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <MUILink href="https://ventrek.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                  <Typography>About</Typography>
                </MUILink>
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </Drawer>

      <Announcement open={announcementOpen} handleClose={handleAnnoucment(false)} data={data} user={user} />
      <ReportDialog open={reportDialogOpen} handleClose={() => setReportDialogOpen(false)} user={user} snack={snack} />
      <ContactDialog open={contactDialogOpen} handleClose={() => setContactDialogOpen(false)} user={user} snack={snack} />
    </>
  );
};

export default Navbar;

const GET_ORG_AND_DEPARTMENTS = gql`
  query Navbar_GetOrgDepts($id: ID!, $user: ID) {
    organization(id: $id) {
      id
      name
      active
      bhag {
        goal
        targetDate
      }
      core {
        purpose
        value
        process
        market
        offering
        valueProposition
        differentiation
        competency
      }
    }

    todos(organization: $id, user: $user, done: false, category: "todo") {
      id: _id
    }

    issues: todos(organization: $id, user: $user, done: false, category: "issue") {
      id: _id
    }

    rocks(organization: $id, user: $user) {
      id
    }

    plans(organization: $id, category: "1 year", closed: false) {
      id
      departmentName
      sharedPlanId
      color
      shortName
      year
    }
  }
`;

const renderPathname = (pathname) => {
  if (_.isEmpty(pathname)) {
    return "";
  } else {
    let path = pathname.split("/")[2];
    if (_.isEmpty(path)) {
      return "";
    }
    let location = "";
    switch (path) {
      case "1-year":
        location = "1 Year Plan";
        break;
      case "3-year":
        location = "3 Year Plan";
        break;
      case "accountability-chart":
        location = "R&R Chart";
        break;
      case "":
        location = "Dashboard";
        break;
      default:
        location = _.startCase(path);
        break;
    }
    return `- ${location}`;
  }
};
