import React, { useEffect, useContext } from "react";
import styles from "./AddObjectiveDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery } from "@material-ui/core";
import { UserContext } from "../../context/userContext";
import { isAuthed } from "../../utils/authorization";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "../Loading/Loading";
import SelectUsers from "../SelectUsers/SelectUsers";
import SelectObjectives from "../SelectObjectives/SelectObjectives";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import { OBJECTIVE_FIELDS } from "../../utils/fragments";
import { GET_OBJECTIVES } from "../../utils/query";

const initForm = {
  value: null,
  description: null,
  users: [],
  objectives: [],
  rocks: [],
  plan: null,
};

const AddObjectiveDialog = ({ dialog, setDialog, requestFetch, fetch, params, snack }) => {
  const { user } = useContext(UserContext);
  const { sharedPlanId } = _.get(user, "departmentFilter");
  const oneYearCorpPlan = _.get(dialog, "addObjectiveDialog.oneYearCorpPlan"); // this will be three year corp if it is adding three year
  const category = _.get(dialog, "addObjectiveDialog.category");
  const variables = _.get(dialog, "addObjectiveDialog.variables");
  
  const [createObjective, { loading: mutationLoading }] = useMutation(CREATE_OBJECTIVE, {
    update(cache, { data: { createObjective } }) {
      try {
        if (_.isNil(variables)) {
          throw new Error("variable cannot be null or undefined");
        }
        const newObjective = createObjective.objective;
        const existingData = cache.readQuery({
          query: GET_OBJECTIVES,
          variables,
        });
        cache.writeQuery({
          query: GET_OBJECTIVES,
          variables,
          data: {
            objectives: [newObjective, ...existingData.objectives],
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });

  const {
    data,
    refetch,
    loading: queryLoading,
  } = useQuery(GET_USERS_AND_OBJECTIVES_AND_PLANS, {
    variables: {
      organization: params.org,
      category,
      sharedPlanId,
      oneYearCorpPlan: oneYearCorpPlan,
      closed: _.isNil(oneYearCorpPlan) ? false : null,
    },
  });

  const fs = useMediaQuery("(max-width: 600px)");

  let initErrorForm = {
    value: ["required"],
    plan: ["required"],
  };

  const { form, formErrors, handleChange, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleSubmit = (close) => async () => {
    if (!validateForm()) return;

    const { value, description, users, objectives, rocks, plan } = form;
    const category = _.get(dialog, "addObjectiveDialog.category");

    const res = await createObjective({
      variables: {
        organization: params.org,
        plan,
        category,
        value,
        description,
        users,
        objectives,
        rocks,
      },
    });
    if (res) {
      snack(`Created "${value}" objective`);
      // requestFetch();
      resetForm({ ...initForm, plan });
      if (close) {
        handleClose();
      }
    } else {
      snack("Something went wrong, please try again later", "error");
    }
  };

  const handleClose = () => {
    resetForm();
    setDialog({ ...dialog, addObjectiveDialog: { open: false } });
  };

  // Reftech the list of available objectives to tie into
  useEffect(() => {
    if (dialog.addObjectiveDialog.open) {
      refetch();
    }
  }, [fetch, dialog.addObjectiveDialog.open]);

  const departmentFilterSharedId = _.get(user, "departmentFilter.sharedPlanId");
  useEffect(() => {
    const plans = _.get(data, "plans", []);
    const planWithSameSharedPlanId = _.find(plans, (plan) => plan.sharedPlanId === departmentFilterSharedId);

    if (dialog.addObjectiveDialog && departmentFilterSharedId && !queryLoading) {
      resetForm({ ...initForm, plan: _.get(planWithSameSharedPlanId, "id", null) });
    } else {
      resetForm();
    }
  }, [dialog.addObjectiveDialog, departmentFilterSharedId, queryLoading]);

  if (queryLoading)
    return (
      <Dialog
        open={dialog.addObjectiveDialog.open === true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullScreen={fs}
        fullWidth
      >
        <Loading />
      </Dialog>
    );

  const isThreeYear = _.get(dialog, "addObjectiveDialog.category") === "3 year";

  return (
    <Dialog
      open={dialog.addObjectiveDialog.open === true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullScreen={fs}
      fullWidth
    >
      <DialogTitle>
        <div className={styles.title}>
          Create New Objective
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Objective Title"
          name="value"
          fullWidth
          variant="outlined"
          value={form.value || ""}
          onChange={handleChange}
          helperText={formErrors.value}
          error={Boolean(formErrors.value)}
        />
        <TextField
          label="Why and How"
          name="description"
          fullWidth
          multiline
          variant="outlined"
          value={form.description || ""}
          onChange={handleChange}
          margin="normal"
        />
        <SelectObjectives
          name="objectives"
          objectives={_.get(data, "objectives", null)}
          handleChange={handleChange}
          values={form.objectives}
          category={isThreeYear ? "3 year" : "1 year"}
          multiple
          helperText="Which objectives are tied to this one?"
          plansOrder={_.get(data, "organization.plansOrder")}
        />
        <SelectDepartment
          plans={_.get(data, "plans")}
          name="plan"
          handleChange={handleChange}
          value={form.plan}
          helperText={formErrors.plan}
          error={Boolean(formErrors.plan)}
          // disabled={!_.isNil(departmentFilterSharedId)}
        />
        <SelectUsers
          name="users"
          users={_.get(data, "users")}
          handleChange={handleChange}
          values={form.users}
          helperText="Who is accountable for this objective?"
          plan={form.plan}
          allPlans={_.get(data, "plans")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(false)} color="primary" variant="outlined" disabled={mutationLoading} className={styles.button}>
          {mutationLoading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
        </Button>
        <Button onClick={handleSubmit(true)} color="primary" variant="contained" disabled={mutationLoading} className={styles.button}>
          {mutationLoading ? <Loading size={24} color="#fff" /> : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddObjectiveDialog;

const GET_USERS_AND_OBJECTIVES_AND_PLANS = gql`
  query AddObjectiveDialog_GetUsersObjectivesPlans(
    $organization: ID!
    $category: String
    $sharedPlanId: ID
    $oneYearCorpPlan: ID
    $closed: Boolean
  ) {
    organization(id: $organization) {
      id
      plansOrder
    }

    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    objectives(organization: $organization, archived: false, sharedPlanId: $sharedPlanId) {
      id
      value
      category
      number
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: $category, oneYearCorpPlan: $oneYearCorpPlan, closed: $closed) {
      id
      departmentName
      sharedPlanId
      category
      year
      plan {
        id
      }
    }
  }
`;

const CREATE_OBJECTIVE = gql`
  ${OBJECTIVE_FIELDS}
  mutation CreateOneYearObj(
    $value: String!
    $description: String
    $organization: ID!
    $plan: ID!
    $category: String!
    $users: [ID!]
    $objectives: [ID!]
  ) {
    createObjective(
      value: $value
      description: $description
      organization: $organization
      plan: $plan
      category: $category
      users: $users
      objectives: $objectives
    ) {
      objective {
        ...ObjectiveFields
      }
      plan {
        id
        objectives
      }
      objectives {
        id
        objectives
      }
    }
  }
`;
