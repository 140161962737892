import React, { createContext, useEffect, useState } from "react";
import _ from "lodash";
import jwt from "jsonwebtoken";
import { getUser } from "../utils/graphql";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState();

  const refreshUser = async (id) => {
    const { data } = await getUser(id);
    if (data.user) {
      const token = jwt.sign(data.user, process.env.REACT_APP_JWT_SECRET);
      localStorage.setItem("token", token);

      let departmentFilterId = sessionStorage.getItem("departmentFilterId");
      let departmentFilterSharedId = sessionStorage.getItem("departmentFilterSharedId");

      let departmentFilter = {
        id: departmentFilterId,
        sharedPlanId: departmentFilterSharedId,
      };

      if (_.isNil(departmentFilterId)) {
        departmentFilter = { id: null, sharedPlanId: null };
      }

      setUser({
        user: data.user,
        departmentFilter,
      });
    } else {
      setUser();
    }
  };

  return <UserContext.Provider value={{ user, setUser, refreshUser }}>{children}</UserContext.Provider>;
};
