import React from "react";
import _ from "lodash";
import styles from "./ContactDialog.module.scss";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, TextField } from "@material-ui/core";
import { useForm } from "../../hooks";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import Loading from "../Loading/Loading";

const initForm = {
  subject: null,
  message: null,
};

const initErrorForm = {
  subject: ["required"],
  message: ["required"],
};

const ContactDialog = ({ open, handleClose, user, snack }) => {
  const [sendContactMessage, { loading }] = useMutation(SEND_CONTACT_MSG);

  const { form, formErrors, handleChange, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const { message, subject } = form;

    const res = await sendContactMessage({
      variables: {
        subject,
        message,
        user: user.user.id,
      },
    });

    if (_.get(res, "data.sendContactMessage", false)) {
      snack("Message is sucessfully submitted");
      resetForm();
      handleClose();
    } else {
      snack("something went wrong", "error");
    }
  };

  const submitDisabled = loading || !form.message || !form.subject;

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Contact Us</DialogTitle>
      <DialogContent className={styles.text}>
        <Typography variant="subtitle2">Have any questions or specific inquries? Contact us at:</Typography>
        <Typography variant="subtitle1">Email: info@ventrek.app</Typography>
        <Typography variant="subtitle1">Phone: (587) 582-3713</Typography>
        <Typography variant="subtitle2">or use the contact form below: </Typography>
        <TextField
          autoFocus
          label={"Subject"}
          name="subject"
          fullWidth
          variant="outlined"
          margin="normal"
          value={form.subject || ""}
          onChange={handleChange}
          helperText={formErrors.subject}
          error={Boolean(formErrors.subject)}
        />
        <TextField
          label={"Message"}
          name="message"
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          rows={3}
          value={form.message || ""}
          onChange={handleChange}
          helperText={formErrors.message}
          error={Boolean(formErrors.message)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained" disabled={submitDisabled} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactDialog;

const SEND_CONTACT_MSG = gql`
  mutation ($message: String!, $subject: String!, $user: ID!) {
    sendContactMessage(subject: $subject, message: $message, user: $user)
  }
`;
