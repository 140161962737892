import React, { useState, useEffect, useContext } from "react";
import styles from "./FiveYearVision.module.scss";
import useForm from "../../hooks/useForm";
import _ from "lodash";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

const initErrorForm = {
  name: ["required"],
};

const EditDialog = ({ id, open, handleClose, initForm }) => {
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [updateBhag] = useMutation(UPDATE_BHAG);

  const { form, formErrors, handleChange, handleChangeDate, resetForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleSubmitBhag = async () => {
    const { goal, targetDate } = form;
    const ok = await updateBhag({
      variables: {
        id,
        goal: goal,
        targetDate: new Date(targetDate),
      },
    });

    if (ok) {
      snack("Updated BHAG");
      requestFetch();
      handleClose();
    }
  };

  useEffect(() => {
    resetForm(initForm);
  }, [open]);

  return (
    <>
      <Dialog
        open={Boolean(open.bhag)}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullWidth
      >
        <DialogTitle>Create New BHAG</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Big Hairy Audacious Goal"
            name="goal"
            margin="normal"
            fullWidth
            multiline
            variant="outlined"
            value={form.goal || ""}
            onChange={handleChange}
            helperText={formErrors.goal}
            error={Boolean(formErrors.goal)}
          />
          <DatePicker
            fullWidth
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="normal"
            label="Target Date"
            value={form.targetDate || ""}
            onChange={handleChangeDate("targetDate")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitBhag} color="primary" variant="contained" className={styles.button}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDialog;

const UPDATE_BHAG = gql`
  mutation ($id: ID!, $goal: String!, $targetDate: String!) {
    updateBhag(organization: $id, goal: $goal, targetDate: $targetDate)
  }
`;
