import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText } from "@material-ui/core";
import Loading from "../../components/Loading/Loading";

const CreateThreeYearDialog = ({ open, handleClose, nextPlanYearEnd, nextPlanYearRange, organizationId }) => {
  const [confirmText, setConfirmText] = useState();
  const [loading, setLoading] = useState(false);
  const [createNewThreeYear] = useMutation(CREATE_NEW_THREE_YEAR_PLAN);

  const handleChange = (e) => {
    setConfirmText(e.target.value);
  };

  const handleCreateNewYear = async () => {
    setLoading(true);
    const res = await createNewThreeYear({ variables: { id: organizationId } });
    setLoading(false);
    if (res.data.createThreeYearPlans) {
      window.location.reload();
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={
        loading
          ? null
          : (event, reason) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }
      }
      fullWidth
    >
      <DialogTitle>Create {nextPlanYearRange} Plan</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to create three year plan between {nextPlanYearRange}. This process cannot be undone.
        </DialogContentText>
        <DialogContentText>
          Please type <code>{nextPlanYearEnd}</code> to proceed.
        </DialogContentText>
        <TextField variant="outlined" fullWidth value={confirmText || ""} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleCreateNewYear} disabled={confirmText !== nextPlanYearEnd || loading}>
          {loading ? <Loading color="#fff" size={24} /> : `Create`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateThreeYearDialog;

const CREATE_NEW_THREE_YEAR_PLAN = gql`
  mutation ($id: ID!) {
    createThreeYearPlans(id: $id)
  }
`;
